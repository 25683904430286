/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.shouldUpdateScroll = ({
                                  routerProps: { location },
                                  getSavedScrollPosition,
                              }) => {
    console.log("LOCATION: ", location)
    const { pathname } = location
    // list of routes for the scroll-to-top-hook`
    const scrollToTopRoutes = [`/`, `/portfolio`,`/en`, `/en/`, `/en/portfolio` ]
    // if the new route is part of the list above, scroll to top (0, 0)
    if (scrollToTopRoutes.indexOf(pathname) !== -1 && location.hash === '') {
        console.log("Idemo skrolovanje")
        document.querySelector("#gatsby-focus-wrapper").scroll(0,0)
    }else {
        console.log("Idemo linkovanje")
        if(location.hash !== '') {
            document.querySelector(`${location.hash}`).scrollIntoView({behavior: "smooth"})
        }
    }

    return false
}
